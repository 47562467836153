<template>
	<div class="administrator-box">
		<div class="user-list">
			<div class="top-box"><span class="title">用户列表<i>（选择用户分配管理公司）</i></span></div>
			<div class="list-user-box">
				<vue-scroll>
					<el-radio-group v-model="user" size="medium" @change="setTransfer">
						<el-radio v-for="(item, index) in userList" :key="index" :label="item.id" border>{{ item.name }}</el-radio>
					</el-radio-group>
				</vue-scroll>
			</div>
		</div>
		<el-row type="flex" class="row-bg transfer-box" justify="center" v-if="transferShow">
			<el-col :span="24">
				<el-transfer
					filterable
					:filter-method="filterMethod"
					filter-placeholder="请输入城市名称"
					v-model="selectCompany"
					:data="transferData"
					:titles="['未分配公司', '当前管理公司']"
					@change="currentCompany"
					:props="dataAlias"
				></el-transfer>
			</el-col>
			<el-button type="primary" @click="addCompany">修改</el-button>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'administrator',
	data() {
		return {
			user: null, //选择的用户
			userList: [], //用户选择列表
			transferShow: false, //穿梭框是否渲染
			transferData: [], // 穿梭框数据
			selectCompany: [], // 穿梭框选中的公司id
			selectCompanyData: [],//穿梭框选中的公司数据
			dataAlias: {
				key: 'id',
				label: 'name'
			} // 数据源的字段别名
		};
	},
	created() {
		this.getData();
	},
	mounted() {},
	computed: {},
	methods: {
		// 获取页面所需数据
		getData() {
			this.userList = [];
			this.axios.post('web2/aupp/lsji/glist').then(res => {
				if (res.status) {
					this.userList = res.data.a;
					this.transferData = res.data.b;
				}
			});
		},
		// 穿梭框设置数据
		setTransfer(val) {
			// // console.log(val);
			this.selectCompany = [];
			var len = this.userList.length;
			for (let i = 0; i < len; i++) {
				let item = this.userList[i];
				if (item.id == this.user) {
					item.b.forEach(v => {
						this.selectCompany.push(v.id);
					});
					break;
				}
			}
			this.transferShow = true;
		},
		filterMethod(query, item) {
			return item.name.indexOf(query) > -1;
		},
		// 穿梭框当前管理公司数据发生变化
		// 参数：当前值、数据移动的方向（'left' / 'right'）、发生移动的数据 key 数组
		currentCompany(data, direction, keyArr) {
			// // console.log(this.selectCompany);
			// // console.log(data)
			// // console.log(direction)
			// // console.log(keyArr)
			if (direction == 'right') {
				// this.addCompany(data);
			} else {
				// this.removeCompany(data);
			}
			this.selectCompanyData = [];
			var len = this.transferData.length;
			for(let i = 0; i < len; i++){
				let item = this.transferData[i];
				if(this.selectCompany.indexOf(item.id) != -1){
					this.selectCompanyData.push(item);
				}
			}
			
		},
		// 添加管理公司
		addCompany() {
			var selectCompany = this.selectCompany;
			var data = {
				id: this.user, //用户id
				ids: selectCompany.toString() //公司id
			};
			this.axios.post('web2/aupp/lsji/maac', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '用户管理公司修改成功！',
						type: 'success'
					});
					var len = this.userList.length;
					var lens
					for (let i = 0; i < len; i++) {
						let item = this.userList[i];
						let arr = [];
						if (item.id == this.user) {
							this.selectCompanyData.forEach((val)=>{
								arr.push(val);
							});
							this.userList[i].b = arr;
							break;
						}
					}
				}
			});
		}
		// 移除管理公司(暂时无用)
		// removeCompany() {
		// 	var data = {
		// 		id: this.user, //用户id
		// 		ids: val.toString() //公司id
		// 	};
		// 	this.axios.post('web2/aupp/lsji/', data).then(res => {});
		// }
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.administrator-box {
	width: 100%;
	height: 100%;

	.row-bg {
		width: 100%;

		.el-col {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			::v-deep {
				.el-select {
					width: 500px;
					margin-top: 10px;
				}

				.el-transfer {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: flex-start;

					.el-transfer-panel {
						width: calc(45% - 91px);
						height: 100%;

						.el-transfer-panel__body {
							height: 100%;
						}
						
						.el-transfer-panel__list{
							height: calc(100% - 102px);
						}
					}
				}
			}
		}
	}

	.user-list {
		width: calc(100% - 45px);
		height: calc(30% - 26px);
		padding: 8px 15px 8px 0;
		margin: 0 auto;
		margin-top: 10px;
		border: thin solid #dcdfe6;

		.top-box {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 8px;

			.title {
				font-size: 16px;
				font-weight: 600;
				color: #000000;
				margin-left: 15px;
				
				i{
					font-size: 13px;
					font-weight: 500;
					margin-left: 5px;
				}
			}
		}

		.list-user-box {
			width: 100%;
			height: calc(100% - 30px);

			::v-deep {
				.el-radio {
					margin: 0 0 15px 18px;
					padding: 10px 10px 0 10px;
				}
			}
		}
	}

	.transfer-box {
		height: calc(70% - 40px);
		margin-top: 30px;
		position: relative;

		button {
			width: 70px;
			height: 40px;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}
}
</style>
